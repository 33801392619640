<template>
  <div class="content-section">

    <div class="highlights-header">
      <h2 class="section-title">{{ title }}</h2>
      <div class="section-description subtitle">
        <p>{{ description }}</p>
<!--        <router-link to="/all-news" class="view-all" style="color: #409eff; text-decoration: none;">-->
<!--          查看更多 >-->
<!--        </router-link>-->
      </div>
    </div>

    <el-row :gutter="20">
      <el-col
          v-for="(item, index) in items"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="8"
      >
<!--      <el-col :span="8" v-for="(item, index) in items" :key="index">-->
        <el-card class="fixed-size-card">
          <div class="card-content">
            <span>{{ item.title }}</span>
            <span> {{ item.date_time }}</span>
            <p v-html="item.description"></p>
          </div>
        </el-card>

      </el-col>
    </el-row>
  </div>

</template>

<script>
// import axios from "axios";
import {ElCard, ElCol, ElRow} from 'element-plus'
export default {
  name: 'ContentSection',
  components: { ElCard, ElRow, ElCol },
  data() {
    return {
      title: '热点叙事',
      description: '加密经济永远不缺新的叙事，远的有早几年的Defi Summer、加密猫、GameFi、平台币、新Layer1、DePIN、ZKRollup等等，近的有23年的比特币铭文，' +
          '24年至今加密经济虽然缺少跟去年比特币铭文相提并论的新叙事逻辑，但也还围绕着“BTC减半”、“现货ETF上市”、“降息”、“各种诉讼赔偿结案”等主旋律，出现了诸如老币换发新春（SOL）、' +
          'Layer1新王有望引领新一轮牛市等热点或舆情，ZKware LAB持续跟踪相关赛道的投资机会，并无偿分享给社区。',
      items: [
        {
          title: "AI赛道",
          description: "12月OpenAI为期12天的新品发布会注定在投资市场掀起不小的波澜，ZKware LAB 看好AI赛道在12月的表现，核心关注标的有 $WLD $RENDER $LPT $CLANKER  等，AI MEME 与 AI agent 也是潜在爆发机会 ，如 $ACT $GOAT $TURBO 等波动较大，请注意做好风险控制。 ",
          date_time: "2024-12-05"
        },
        { 
          title: "RWA赛道",
          description: "RWA（真实世界资产，Real World Assets）赛道是指将现实世界中的资产（如房地产、商品、债券等）数字化并在区块链上进行交易和管理的领域。这一赛道的核心在于通过区块链技术实现资产的透明化、可追溯性和高效流通。赛道中如$MKR $ONDO $IOTA等均是高潜力标的，值得关注。",
          date_time: "2024-12-05"
        },
        {
          title: "SOL",
          description: "<a href=\"https://solana.com/\" target=\"_blank\">SOL</a>仍是本轮牛市的发动机，链上生态异常繁荣，phantom钱包已经成为新人入场首选，MEME赛道热度独步天下，造富神话自24年起不绝。自24年9月加入榜单以来，最大涨幅超100%+，仍看好其后市表现。",
          date_time: "2024-12-01"
        },
        {
          title: "SUI",
          description: "<a href=\"https://sui.io/\" target=\"_blank\">SUI</a>\n" +
              "      是MOVE生态3大公链之一，目前生态内TVL与市值均位列第一。自24年9月加入榜单以来，最大涨幅超300%+，仍看好其后市表现。SUI在油管上知名频道\n" +
              "      <a href=\"https://www.youtube.com/watch?v=EfNlejjM1Cc\">CoinBureau</a> 、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=gjN8gwtvioQ\">Paul Barron</a>、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=GJOxJWLRWv0\">Think Crypto</a> 、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=rnMzUH7oNk4\">脑哥区块链</a> 、\n" +
              "      <a href=\"https://www.youtube.com/watch?v=6GdQ79WVAAM\">区块链日报</a> 等获得频繁曝光。",
          date_time: "2024-12-01"
        },
        {
          title: "XRP",
          description: "<a href=\"https://xrpl.org/\"  target=\"_blank\">XRP</a>\n" +
              "      在24年12月成功拿下市值榜TOP3，30D涨幅430%+，成为Q4特朗普交易中因加密政策宽松最大赢家。\n" +
              "      ZKware LAB目前正按计划逐步止盈减持，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于XRP的舆情，与社区分享。",
          date_time: "2024-12-01"
        },
        {
          title: "DOGE",
          description: "<a href=\"https://dogecoin.com/\"  target=\"_blank\">DOGE</a>\n" +
              "      MEME赛道市值TOP1，是11月受益于特朗普当选最显著的标的。\n" +
              "      自24年9月加入榜单以来，最大涨幅超300%+，因马斯克主导政府效率部(DOGE)将使其话题度贯穿整个2025，仍看好其后市表现。\n" +
              "      ZKware LAB目前正按计划逐步止盈减持，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于DOGE的舆情，与社区分享。",
          date_time: "2024-12-01"
        },
        {
          title: "ALEO",
          description: "<a href=\"https://aleo.org/\" target=\"_blank\">ALEO</a>\n" +
              "      简单的说来，就是构建了一个零知识证明的开发平台，最近Tornado Cash 官司胜诉的消息给隐私赛道带来新的机遇和希望。\n" +
              "      ZKware LAB从Testnet2开始就深度参与这个项目，并且也重仓持有aleo，并持续跟踪相关进展，以下通过Chatgpt每日跟踪精选关于ALEO的舆情，与社区分享。",
          date_time: "2024-12-01"
        },
      ]
    }
  }


}
</script>

<style scoped>
.content-section {
  margin-bottom: 10px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.fixed-size-card {
  height: 400px;
  display: block;
  flex-direction: column;
}

.card-content {
  height: 320px;
  flex-grow: 1;
  overflow-y: auto;
}

.card-footer {
  padding: 10px;
  border-top: 1px solid #ebeef5;
  font-size: 0.9em;
  color: #606266;
}

.news-item {
  margin-bottom: 10px;
}

.link {
  display: block;
  text-align: left;
  color: #409EFF;
  text-decoration: none;
  font-size: 0.95em;
  line-height: 1.4;
}

.link:hover {
  text-decoration: underline;
}

.date {
  font-size: 0.85em;
  color: #909399;
  margin-left: 5px;
}
</style>